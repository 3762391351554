import {
  SET_TOKEN,
  SET_LOGIN_USER,
  RESET_USER,
  SET_LOGIN_STRATEGIES,
  SET_SIGNUP_RESULT
} from '../actions/types';

const initialState = {
  authenticated: false,
  token: null,
  user: null,
  loginStrategies: [],
  signupStatus: null
};

const root = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOGIN_USER:
      return {
        ...state,
        user: payload
      };
    case SET_TOKEN:
      return {
        ...state,
        token: payload,
        authenticated: true,
      };
    case SET_LOGIN_STRATEGIES:
      return {
        ...state,
        loginStrategies: payload,
      };

    case SET_SIGNUP_RESULT:
      return {
        ...state,
        signupStatus: payload.status
      };

    case RESET_USER:
      return {
        state: initialState
      };

    default:
      return state;
  }
};

export default root;
