import React, { useMemo } from 'react';
import Modal from '../../../common/Modal/Modal';
import './NotWorkingModal.scss';
import Button from '../../../common/Button/Button';

import { ReactComponent as Return } from '../../../../assets/svg/return.svg';
import { ReactComponent as Email } from '../../../../assets/svg/email.svg';
import { ReactComponent as Login } from '../../../../assets/svg/login.svg';
import { useTranslation, Trans } from 'react-i18next';

interface NotWorkingModalProps {
  onClose: () => void;
}

const emailBody = `Hi Support Team,
I am experiencing an issue with [app name].
      
Issue Description:
[Provide a detailed description of the problem you're experiencing, including any error messages or unexpected behavior.]

Additional Information:
[Any other relevant information that might help diagnose the problem, such as screenshots or specific scenarios when the issue occurs, or any sign in methods you may have used (phone number, emails etc.)]


Best regards,
[Your Name]


We appreciate you reaching out, we will get back to you soon.

Device Information:
* Device Type: [e.g., iPhone X]
* Operating System: [e.g., iOS 15.3]
`;

const NotWorkingModal: React.FC<NotWorkingModalProps> = ({ onClose }) => {
  const { t } = useTranslation(); 
  const email = useMemo(
    () =>
      `mailto:rownd.io?subject=${encodeURIComponent(`Support`)}&body=${encodeURIComponent(emailBody)}`,
    []
  );

  return (
    <Modal className="pdc-not-working" onClose={onClose}>
      <div className="pdc-not-working__title">{t('Not working?')}</div>
      <div className="pdc-not-working__subtitle">
        {t('Here are a few things to try.')}
      </div>
      <div className="pdc-not-working__item">
        <div className="pdc-not-working__item__icon">
          <div className="pdc-not-working__item__icon__background" />
          <Return />
        </div>
        <div className="pdc-not-working__item__text">
          {t('Go back to the app or website you signed in from. You might already be signed in.')}
        </div>
      </div>
      <div className="pdc-not-working__item">
        <div className="pdc-not-working__item__icon">
          <div className="pdc-not-working__item__icon__background" />
          <Login />
        </div>
        <div className="pdc-not-working__item__text">
          {t('Try signing in again. Something might have failed during verification.')}
        </div>
      </div>
      <div className="pdc-not-working__item">
        <div className="pdc-not-working__item__icon">
          <div className="pdc-not-working__item__icon__background" />
          <Email />
        </div>
        <div className="pdc-not-working__item__text">
          <Trans
            i18nKey='Still not working? <a>Send us a support email</a>, and
            we’ll see what we can do.'
            components={{
              a: <a href={email}>Send us a support email</a>
            }}
          />
        </div>
      </div>
      <Button onClick={onClose} title={t('Close')} type="tertiary" />
    </Modal>
  );
};

export default NotWorkingModal;
