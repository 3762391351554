export const WebSwoop1 = ({ opacity }) => {
  return (
    <svg viewBox="0 0 733 355" version="1.1">
      <title>web-swoop-1</title>
      <g
        id="Emails-and-Verify-screens-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={opacity || '0.1'}
      >
        <g
          id="xlg-1312px-16-column-copy-143"
          transform="translate(-274.000000, -490.000000)"
          fill="currentColor"
        >
          <path
            d="M1009,490 L274.5,490.301775 C401.603237,598.058951 515.269903,676.291693 615.5,725 C715.730097,773.708307 846.730097,814.374974 1008.5,847 L1009,490 Z"
            id="web-swoop-1"
            transform="translate(641.750000, 668.500000) scale(-1, -1) translate(-641.750000, -668.500000) "
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const WebSwoop2 = ({ opacity }) => {
  return (
    <svg viewBox="0 0 767 355" version="1.1">
      <title>web-swoop-2</title>
      <g
        id="Emails-and-Verify-screens-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={opacity || '0.1'}
      >
        <g
          id="xlg-1312px-16-column-copy-143"
          transform="translate(-272.000000, -490.000000)"
          fill="currentColor"
        >
          <path
            d="M1039,490 L273,490 L272.702434,524.257363 C459.105065,558.586127 573.537587,584.500339 616,602 C726,647.333333 866.833333,729 1038.5,847 L1039,490 Z"
            id="web-swoop-2"
            transform="translate(655.851217, 668.500000) scale(1, -1) translate(-655.851217, -668.500000) "
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const WebSwoop3 = ({ opacity }) => {
  return (
    <svg viewBox="0 0 763 263" version="1.1">
      <title>web-swoop-3</title>
      <g
        id="Emails-and-Verify-screens-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={opacity || '0.1'}
      >
        <g
          id="xlg-1312px-16-column-copy-143"
          transform="translate(-275.000000, -582.000000)"
          fill="currentColor"
        >
          <path
            d="M275,845.527397 L275,582 C386.333333,683.712329 502,742.890411 622,759.534247 C742,776.178082 880.666667,758.30137 1038,705.90411 L1038,852 L275,845.527397 Z"
            id="web-swoop-3"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MobileSwoop1 = ({ opacity }) => {
  return (
    <svg width="320px" height="254px" viewBox="0 0 320 254" version="1.1">
      <title>mobile-swoop-1</title>
      <g
        id="Emails-and-Verify-screens-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={opacity || '0.1'}
      >
        <g
          id="sm-320px-4-column-copy-41"
          transform="translate(0.000000, -438.000000)"
          fill="currentColor"
        >
          <path
            d="M323,436.906503 L-1,437.906503 L-0.50161874,510.672767 C53.9717795,529.479046 91.9696247,543.556958 113.491917,552.906503 C183.341381,583.24996 252.780337,630.258181 321.808787,693.931169 C324.898859,696.781502 325.29593,611.106614 323,436.906503 Z"
            id="mobile-swoop-1"
            transform="translate(161.734027, 565.453252) scale(1, -1) translate(-161.734027, -565.453252) "
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MobileSwoop2 = ({ opacity }) => {
  return (
    <svg width="320px" height="234px" viewBox="0 0 320 234" version="1.1">
      <title>mobile-swoop-2</title>
      <g
        id="Emails-and-Verify-screens-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={opacity || '0.1'}
      >
        <g
          id="sm-320px-4-column-copy-41"
          transform="translate(0.000000, -458.000000)"
          fill="currentColor"
        >
          <path
            d="M324,460 L-1,458 L-3,500 C52.3333333,556 98.3333333,596 135,620 C171.612022,643.964232 233.945355,669.297566 322,696 L324,460 Z"
            id="mobile-swoop-2"
            transform="translate(160.500000, 577.000000) scale(-1, -1) translate(-160.500000, -577.000000) "
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MobileSwoop3 = ({ opacity }) => {
  return (
    <svg width="320px" height="159px" viewBox="0 0 320 159" version="1.1">
      <title>mobile-swoop-3</title>
      <g
        id="Emails-and-Verify-screens-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={opacity || '0.1'}
      >
        <g
          id="sm-320px-4-column-copy-41"
          transform="translate(0.000000, -533.000000)"
          fill="currentColor"
        >
          <path
            d="M-2,693 L-1.02497491,532.253493 C44.3250084,585.417831 89.4553625,616.639835 134.366088,625.919505 C193.24904,638.086183 255.793677,625.779681 322,589 L322,693 L-2,693 Z"
            id="mobile-swoop-3"
          ></path>
        </g>
      </g>
    </svg>
  );
};
