import { environment } from '../environment.js';

export const validateEmail = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
);

export const validatePhone = value => (
  value && !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ? 'Invalid phone number'
    : undefined
);

export const validatePostalCode = value => (
  value && !/^\d+$/.test(value)
    ? 'Invalid Postal Code'
    : undefined
);

export const validatePassword = value => {
  let errorMessage = '<ul class="password-error ">';
  let error = false;
  if(value && ! /^(?=.*([A-Z]){1,})(?=.*[a-z]{1,}).*$/.test(value)) {
    error =  true;
    errorMessage += '<li class="password-wrong"><img src="/images/close-icon-red-01.svg" alt=""/>include both lower and upper case letter</li>';
  } else {
    errorMessage += '<li class="password-check"><img src="/images/checkbox-icon-01.svg" alt=""/>include both lower and upper case letter</li>';
  }
  if(value && !/^(?=.*[!@#$&*]{1,})(?=.*[0-9]{1,}).*$/i.test(value)) {
    error =  true;
    errorMessage += '<li class="password-wrong"><img src="/images/close-icon-red-01.svg" alt=""/>include one number and symbol</li>';
  } else {
    errorMessage += '<li class="password-check"><img src="/images/checkbox-icon-01.svg" alt=""/>include one number and symbol</li>';
  }
  if(value && value.length > 7) {
    errorMessage += '<li class="password-check"><img src="/images/checkbox-icon-01.svg" alt=""/>be at least 8 character long</li>';
  } else {
    error =  true;
    errorMessage += '<li class="password-wrong"><img src="/images/close-icon-red-01.svg" alt=""/>be at least 8 character long</li>';
  }
  errorMessage += '</ul>';
  if(error) {
    return errorMessage;
  }
  return undefined;
  // return value && !/^(?=.*([A-Z]){1,})(?=.*[!@#$&*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,100}$/i.test(value)
  // ? '<li>Ensure Your Password is of minimum 8 character, has one digit, uppercase, special case and lowercase letter</li>'
  // : undefined;
};

const minLength = min => value =>
value && value.length < min ? `Must be at least ${min}` : undefined;

export const minLength6 = minLength(6);

export const required = value => (value || typeof value === 'number' ? undefined : 'Required');

export const matchPassword = (value, allValues) => (
  value !== allValues.password
    ? 'You have entered wrong password'
    : undefined
);

export const isValidUrl = (url) => (!/^([-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))$/i.test(url) ? 'Invalid URL' : undefined);

const validURL = (userInput) => {
  return  (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g).test(userInput) ? true: false;
};

export const iconUrl = (icon='https://static.rownd.io/images/default-app-icon.svg', key='') => {
  if(validURL(icon)) {
    return `${icon}?key=${key}`;
  } else {
    return `${environment.STORAGE_URL}${icon}?key=${key}`;
  }
};