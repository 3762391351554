import axios from 'axios';
import { environment } from '../../environment.js';
import { toast } from 'react-toastify';
import {errorMessage} from '../../util/errorComponent';

import {
  SET_TOKEN,
  SET_LOGIN_USER,
  RESET_USER,
  SET_LOGIN_STRATEGIES,
  SET_SIGNUP_RESULT
} from './types';


export const setLoginStrategies = (payload) => {
  return {
    type: SET_LOGIN_STRATEGIES,
    payload
  };
};

export const fetchAccessToken = (getAccessToken) => async (dispatch) => {
  let token = await getAccessToken();
  dispatch(setToken(token));
};

const setToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  return {
    type: SET_TOKEN,
    payload: token
  };
};

export const setLoginUser = (data) => {
  return {
    type: SET_LOGIN_USER,
    payload: data
  };
};

export const resetUser = () => async (dispatch) => {
  delete axios.defaults.headers.common.Authorization;
  dispatch({
    type: RESET_USER,
  });
};


export const userSignup = (values) => async(dispatch) => {
  try {
    await axios.post(`${environment.API_URL}/users/signup`, values);

    dispatch({
      type: SET_SIGNUP_RESULT,
      payload: {
        status: 'success'
      }
    });

    // props.history.push(url);
  } catch (err) {
    const { data } = err.response;
    const { messages } = data;
    toast.error(errorMessage(messages));
    dispatch({
      type: SET_SIGNUP_RESULT,
      payload: {
        status: 'error'
      }
    });
  }
};


export const forgotPassword = () => async () => {
  // try {
  //   // const { data } = await axios.post(`${environment.API_URL}/forgot-password`,values);
  //   // toast.success(data.message);
  //   // history.push('/login');
  // } catch (err) {
  //   if (err.response) {
  //     const { message } = err.response.data;
  //     // toast.error(message);
  //   }
  // }
};
