import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import endUser from './endUser';

const reducers = {
  form: formReducer,
  auth,
  endUser,
};
  const reducer = combineReducers(reducers);


export default reducer;