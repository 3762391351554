import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { setLoginUser, fetchAccessToken } from './redux/actions/auth';
import EndUser from './EndUserV1';
import './App.scss';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-toastify/dist/ReactToastify.css';
import { useRownd } from '@rownd/react';

// App start run form App.js
function App(/* props */) {
  const { is_authenticated, user, auth } = useRownd();
  const dispatch = useDispatch();

  useEffect(() => {
    if (is_authenticated && user) {
      dispatch(setLoginUser(user));
      dispatch(fetchAccessToken(auth.access_token));
    }
  }, [is_authenticated, user, dispatch, auth.access_token]);

  return <EndUser />;
}

// Prop-types to define.
App.propTypes = {
  history: PropTypes.object.isRequired
};

// Mapping object to Props.
const mapStateToProps = (state) => { return state; };

// Mapping function to Props.
const mapDispatchToProps = {
  setLoginUser,
  fetchAccessToken
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
