import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import history from './util/history';

import './util/i18n';
import { RowndProvider } from '@rownd/react';
import { environment } from './environment';
import { DimensionProvider } from './hooks/useDimensions';

ReactDOM.render(
  <RowndProvider
    appKey={environment.ROWND_APP_KEY}
    apiUrl={environment.ROWND_API_URL}
    hubUrlOverride={environment.ROWND_HUB_BASE_URL}
  >
    <Provider store={store}>
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        <DimensionProvider>
          <App />
        </DimensionProvider>
      </Router>
    </Provider>
  </RowndProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
