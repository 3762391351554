import {
  SET_LOGIN_USER,
  RESET_USER,
  END_USER_SET_APPLICATION,
  SET_APP_ID,
  SET_APPLICATION_DATA_SCHEMA,
  SET_APP_USER_DATA,
  SET_USER_SHARED_ACLS,
  SET_MY_USER_DATA,
  SET_USER_APPLICATIONS,
  SET_USER_APPLICATION_SCHEMA,
  SET_EMAIL_VERIFY,
  SET_USER_EMAIL,
  SET_EMAIL_VERIFY_ERROR,
  SET_ACTIVE_PAGE,
  SET_IS_FETCHING_APPLICATIONS,
  REQUEST_UNCLAIMED_DATA,
  RECEIVE_UNCLAIMED_DATA
} from '../actions/types';

const initialState = {
  authenticated: false,
  isClaimingData: true,
  isFetchingApplications: false,
  application: null,
  appId: null,
  appDataSchema: {},
  appUserData: {},
  userSharedAcls: {},
  meUserData: {},
  applications: [],
  emailVerify: false,
  userEmail:  '',
  verifiedEmail: '',
  emailError: false,
  iconKey: 0,
  perPageRecords: 9,
  activePage: 1
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
    switch (type) {
      case REQUEST_UNCLAIMED_DATA:
        return {
          ...state,
          isClaimingData: true
        };
      case RECEIVE_UNCLAIMED_DATA:
        return {
          ...state,
          isClaimingData: false,
        };
      case SET_LOGIN_USER:
        return {
          ...state,
          authenticated: false
        };

      case RESET_USER:
        return {
          state: initialState
        };
      case SET_APP_ID:
          return {
            ...state,
            appId: payload
          };
      case SET_IS_FETCHING_APPLICATIONS:
        return {
          ...state,
          ...payload,
        };
      case END_USER_SET_APPLICATION:
        return {
          ...state,
          application: payload
        };
      case SET_APPLICATION_DATA_SCHEMA:
        return {
          ...state,
          appDataSchema: payload
        };
      case SET_APP_USER_DATA:
        return {
          ...state,
          appUserData: payload
        };
      case SET_USER_SHARED_ACLS:
        return {
          ...state,
          userSharedAcls: payload
        };
      case SET_MY_USER_DATA:
        return {
          ...state,
          meUserData: payload
        };
      case SET_USER_APPLICATIONS:
        return {
          ...state,
          applications: payload,
          iconKey: Math.random(),
          isFetchingApplications: false
        };
      case SET_USER_APPLICATION_SCHEMA:
        return {
          ...state,
          credential: payload,
          applications: [...state.applications.map(item => {
            if(item.id === payload.application.id) {
              item.schema = payload.schema;
            }
            return item;
          })],
        };
      case SET_EMAIL_VERIFY:
          return {
            ...state,
            emailVerify: payload.token_ok,
            verifiedEmail: payload.email
          };

      case SET_USER_EMAIL:
          return {
            ...state,
            userEmail: payload
          };
      case SET_EMAIL_VERIFY_ERROR:
          return {
            ...state,
            emailError: payload
          };
      case SET_ACTIVE_PAGE:
        return {
          ...state,
          activePage: payload
        };
      default:
        return state;
    }
};

export default reducer;
