import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRownd } from '@rownd/react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './SideNav.scss';
import $ from 'jquery';
import { getMyApplications } from '../../../redux/actions/endUser';
import { iconUrl } from '../../../util/validation';
import { Button } from 'react-bootstrap';

export default function SideNav(props) {

  let dispatch = useDispatch();
  let { is_authenticated } = useRownd();

  let { applications, perPageRecords, iconKey } = useSelector((state) => state.endUser);
  let { token } = useSelector((state) => state.auth);

  let [displayIconNumber, setDisplayIconNumber] = useState(1);
  let [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!is_authenticated || !token) {
      return;
    }

    if (applications.length > 0) {
      setDisplayIconNumber(perPageRecords);
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    dispatch(getMyApplications());

  }, [is_authenticated, applications, dispatch, perPageRecords, token, isLoading]);

  let header = () => {
    $('body').toggleClass('rownd-sidenav-toggle');
  };

  const { pathname } = props;

  return (
    <div className="app-sidebar sidenav rownd-personal-data-center-header-bar">
      <button onClick={() => header()} className="sidebar-menu-icon ">
        <span className="menu-icon"></span>
        <span className="menu-icon"></span>
        <span className="menu-icon"></span>
        <span className="menu-icon"></span>
      </button>

      <ul>
        <li className={`border-bottom-menu menu ${pathname === '/apps' ? 'active' : ''}`}>
          <NavLink to="/apps">
            <div className="clearfix">
              <i className="rownd-apps-dashboard-nav">
                <img src="/images/home-icon.svg" alt="Home" />
              </i>
            </div>
            <span className="nav-text">Home</span>
          </NavLink>
        </li>
        {
          applications.slice(0, displayIconNumber).map((application) => (
            <li className={`menu ${pathname.startsWith(`/apps/${application.id}`) ? 'active' : ''}`} key={application.id}>
              <NavLink to={`/apps/${application.id}`}>
                <div className="clearfix">
                  <i className="">
                    <img src={iconUrl(application.icon, iconKey)} alt={application.name} />
                  </i>
                </div>
                <span className="nav-text">{application.name}</span>
              </NavLink>
            </li>
          ))
        }

        {(displayIconNumber < applications.length) && (
          <li className={'menu'} >
            <Button variant="link" onClick={() => setDisplayIconNumber(displayIconNumber + perPageRecords)}>
              <div className="clearfix">
                <i className="">
                  <img src="/images/add-icon.svg" alt="add" />
                </i>
              </div>
              <span className="nav-text">Add</span>
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
}

// prop-type to define
SideNav.propTypes = {
  pathname: PropTypes.string.isRequired,
};
