import React, { Component } from 'react';
import {
  withRouter,
} from 'react-router-dom';

// App start run form App.js
class Error404Page extends Component {


  render() {
      return (
        <div className="error-page">
          Error404Page
      </div>
      );
  }

}

export default withRouter(Error404Page);
