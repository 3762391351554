// Auth
export const SET_LOGIN_USER = 'SET_LOGIN_USER';
export const RESET_USER = 'RESET_USER';
export const SET_LOGIN_STRATEGIES = 'SET_LOGIN_STRATEGIES';
export const SET_SIGNUP_RESULT = 'SET_SIGNUP_RESULT';
export const SET_TOKEN = 'SET_TOKEN';
export const REQUEST_UNCLAIMED_DATA = 'REQUEST_UNCLAIMED_DATA';
export const RECEIVE_UNCLAIMED_DATA = 'RECEIVE_UNCLAIMED_DATA';

// End User
export const SET_IS_FETCHING_APPLICATIONS = 'SET_IS_FETCHING_APPLICATIONS';
export const END_USER_SET_APPLICATION = 'END_USER_SET_APPLICATION';
export const SET_APP_ID = 'SET_APP_ID';
export const SET_APPLICATION_DATA_SCHEMA = 'SET_APPLICATION_DATA_SCHEMA';
export const SET_APP_USER_DATA = 'SET_APP_USER_DATA';
export const SET_APP_USER_DATA_RESOURCES = 'SET_APP_USER_DATA_RESOURCES';
export const SET_USER_SHARED_ACLS = 'SET_USER_SHARED_ACLS';
export const SET_MY_USER_DATA = 'SET_MY_USER_DATA';
export const SET_USER_APPLICATIONS = 'SET_USER_APPLICATIONS';
export const SET_USER_APPLICATION_SCHEMA = 'SET_USER_APPLICATION_SCHEMA';
export const SET_EMAIL_VERIFY = 'SET_EMAIL_VERIFY';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_EMAIL_VERIFY_ERROR = 'SET_EMAIL_VERIFY_ERROR';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
