import React, { useMemo } from 'react';
import { ReactComponent as Close } from '../../../assets/svg/close-icon-main.svg';
import classNames from 'classnames';
import './Modal.scss';
import { useDimensionContext } from '../../../hooks/useDimensions';

interface ModalProps {
  onClose: () => void;
  className?: string;
  style?: React.CSSProperties;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  className,
  style,
}) => {
  const { width, height } = useDimensionContext();
  const isBottomSheet = useMemo(
    () => width < 450 && height < 1025,
    [height, width]
  );
  return (
    <div
      className={classNames('pdc-modal-container', {
        'pdc-modal-container--bottom-sheet': isBottomSheet,
      })}
    >
      <div onClick={onClose} className="pdc-modal-container__background" />
      <div className={`pdc-modal ${className}`} style={style}>
        <button onClick={onClose} className="pdc-modal__close">
          <Close />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
