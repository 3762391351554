
import React from 'react';
import { NavLink } from 'react-router-dom';

export const errorMessage = (messages) => {
  return (
    <div>
      <h5>Error</h5>
      <ul className="toast-messages">
        {messages?.map((message, key) => (
          <li key={key}>{message}</li>
        )) || <li>Unknown error</li>}
      </ul>
    </div>
  );
};

export const successMessage = (message) => {
  return (
    <div>
      <h5>Success</h5>
      <p>{message}</p>
    </div>
  );
};

export const infoMessage = (message) => {
  return (
    <div>
      {message}
      Click here to <NavLink to="/upgrade-plan" className="info-message-link">learn more</NavLink>
    </div>
  );
};