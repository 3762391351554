import './ErrorFallback.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';

export default function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div role="alert" className="rownd-uncaught-error">
            <Alert show={true} variant="danger">
                <Alert.Heading>Hmmm...something's not quite right.</Alert.Heading>
                <p>We've had a bit of trouble processing your last request. Give it another try,
                    or email our support team at <Alert.Link href="mailto:support@rownd.io">support@rownd.io</Alert.Link> for
                    some rapid assistance.</p>
                <p><strong>More details:</strong> <pre>{error.message}</pre></p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={resetErrorBoundary}>Try again</Button>
                </div>
            </Alert>
        </div>
    );
}

ErrorFallback.propTypes = {
    error: PropTypes.object,
    resetErrorBoundary: PropTypes.func,
};