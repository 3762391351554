/* global pendo */
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function Analytics(props) {
    let { user } = useSelector(state => state.auth);
    const [isInitialized, setInitialized] = useState(false);

    // Short-circuit if pendo hasn't loaded yet
    if (typeof pendo === 'undefined') {
        return null;
    }

    if (isInitialized) {
        pendo.identify({
            visitor: {
                id: user && user.sub,
            },
            account: {
                id: user && user.id,
            }
        });

        return null;
    }

    pendo.initialize({
        visitor: {
            id: user && user.sub,   // Required if user is logged in
            // email:        // Recommended if using Pendo Feedback, or NPS Email
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
            context: props.context,
        },

        account: {
            id: user && `pdc_useraccount_${user.id}`, // Highly recommended
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
        }
    });

    setInitialized(true);
    return null;
}