import React from 'react';
import './Button.scss';

interface ButtonProps {
  onClick: () => void;
  title: string | React.ReactElement;
  className?: string;
  type?: 'tertiary';
}

const Button: React.FC<ButtonProps> = ({ onClick, title, className, type }) => {
  return (
    <button
      className={`pdc-button ${className || ''} ${
        type ? `pdc-button--${type}` : ''
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default Button;
