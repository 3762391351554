import { createContext } from 'react';
import { useContext, useState, useEffect } from 'react';

export const DimensionContext = createContext<
  { width: number; height: number } | undefined
>(undefined);

export function useDimensionContext() {
  const context = useContext(DimensionContext);

  if (context === undefined) {
    throw new Error(
      'useDimensionContext must be used within a DimensionContext Provider'
    );
  }

  return context;
}

export const DimensionProvider = ({ children }: { children: any }) => {
  const [dimensions, setDimensions] = useState({
    width: window.visualViewport?.width || window.innerWidth,
    height: window.visualViewport?.height || window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.visualViewport?.width || window.innerWidth,
        height: window.visualViewport?.height || window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DimensionContext.Provider value={dimensions}>
      {children}
    </DimensionContext.Provider>
  );
};
