export const environment = {
  API_URL: process.env.REACT_APP_API_URL || window.ENV?.REACT_APP_API_URL,
  ROWND_API_URL: process.env.REACT_APP_ROWND_API_URL || window.ENV?.REACT_APP_ROWND_API_URL,
  ROWND_APP_KEY: process.env.REACT_APP_ROWND_APP_KEY || window.ENV?.REACT_APP_ROWND_APP_KEY,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || window.ENV?.REACT_APP_STRIPE_PUBLIC_KEY,
  DEVELOPER_PLAN_PRICE_ID: process.env.REACT_APP_DEVELOPER_PLAN_PRICE_ID || window.ENV?.REACT_APP_DEVELOPER_PLAN_PRICE_ID,
  ENTERPRISE_PLAN_PRICE_ID: process.env.REACT_APP_ENTERPRISE_PLAN_PRICE_ID || window.ENV?.REACT_APP_ENTERPRISE_PLAN_PRICE_ID,
  TEAM_PLAN_PRICE_ID: process.env.REACT_APP_TEAM_PLAN_PRICE_ID || window.ENV?.REACT_APP_TEAM_PLAN_PRICE_ID,
  STORAGE_URL: process.env.REACT_APP_STORAGE_URL || window.ENV?.REACT_APP_STORAGE_URL,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || window.ENV?.REACT_APP_AUTH0_CLIENT_ID
};
