import React from 'react';
import {
  MobileSwoop1,
  MobileSwoop2,
  MobileSwoop3,
  WebSwoop1,
  WebSwoop2,
  WebSwoop3,
} from '../../../common/Swoops/Swoops';

const Swoops = ({ darkModeEnabled }) => {
  return (
    <>
      <div
        className="hub-auth-v2__swoops"
      >
        <WebSwoop1 opacity={darkModeEnabled ? '.05' : '.1'} />
        <WebSwoop2 opacity={darkModeEnabled ? '.05' : '.1'} />
        <WebSwoop3 opacity={darkModeEnabled ? '.05' : '.1'} />
      </div>
      <div
        className="hub-auth-v2__swoops-mobile"
      >
        <MobileSwoop1 opacity={darkModeEnabled ? '.05' : '.1'} />
        <MobileSwoop2 opacity={darkModeEnabled ? '.05' : '.1'} />
        <MobileSwoop3 opacity={darkModeEnabled ? '.05' : '.1'} />
      </div>
      <img
        className="hub-auth-v2__rownd-icon"
        src="/android-chrome-192x192.png"
        alt="missing"
      />
    </>
  );
};

export default Swoops;
