import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Lottie from 'lottie-react';
import { environment } from '../../../environment';
import './HubVerify.scss';
import { useTranslation } from 'react-i18next';
import rison from 'rison';
import Swoops from './Swoops/Swoops';
import { detectColorMode } from './color_mode';
import EmailSuccess from '../../../assets/lottie/email-success.json';
import PhoneSuccess from '../../../assets/lottie/phone-success.json';
import EmailFail from '../../../assets/lottie/email-fail.json';
import PhoneFail from '../../../assets/lottie/phone-fail.json';
import LinkFail from '../../../assets/lottie/link_failed.json';
import { loadSpecificFont, newHexShade } from '../../../util/utils';
import NotWorkingModal from './NotWorkingModal/NotWorkingModal';

interface HubVerifyProps {
  location: Location;
}

enum CompleteErrorTypes {
  Expired = 'EXPIRED',
  NotFound = 'NOT_FOUND',
  UserFound = 'USER_FOUND',
  UserNotFound = 'USER_NOT_FOUND'
}

type CompleteError = {
  show: boolean;
  canRetry: boolean;
  type?: CompleteErrorTypes 
}

type DisplayAttributes = {
  darkMode?: 'enabled' | 'auto';
  primaryColor?: string;
  primaryColorDark?: string;
  backgroundColor?: string;
  visualSwoops?: 'true' | 'false';
  fontFamily?: string;
  hideVerificationIcons?: 'true' | 'false';
  emailImage?: string;
  redirect?: 'true' | 'false';
};

enum ModalTypes {
  NotWorking,
}

export default function HubVerify(props: HubVerifyProps) {
  const { t } = useTranslation();
  const [verified, setVerified] = useState(true);
  const [verifying, setVerifying] = useState(true);
  const [completeError, setCompleteError] = useState<CompleteError | null>(null);
  // eslint-disable-next-line no-unused-vars
  const [resending, setResending] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [resendingComplete, setResendingComplete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [resendError, setResendError] = useState(null);
  const [challengeKey, setChallengeKey] = useState<"email" | "phone">("email");
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [appName, setAppName] = useState(null);
  const [redirectDomain, setRedirectDomain] = useState<string | null>(null);
  const [initiatingUserAgentType, setInitiatingUserAgentType] =
    useState('desktop');
  const [modal, setModal] = useState<null | ModalTypes>(null);
  const [postDelay, setPostDelay] = useState(false);

  const attributes = useMemo<DisplayAttributes>(() => {
    try {
      const searchParams = new URLSearchParams(props.location.search);

      if (searchParams.has('attributes')) {
        return rison.decode(searchParams.get('attributes') || '{}');
      }

    } catch (err) {
      console.error('Failed to parse attributes', err);
      return {};
    }

    return {};
  }, [props.location.search]);

  const disableRedirect = attributes?.redirect === 'false';

  const preferredColorMode = useMemo(() => {
    return detectColorMode();
  }, []);

  useEffect(() => {
    setTimeout(() => setPostDelay(true), 6000)
  }, [])

  useEffect(() => {
    (async function () {
      const searchParams = new URLSearchParams(props.location.search);
      const challengeToken = searchParams.get('token');
      const challengeId = searchParams.get('id');
      const challengeEmail = searchParams.get('email');
      const challengePhone = searchParams.get('phone');
      const challengeType = searchParams.get('type');

      const challengeKey = !!challengeEmail ? 'email' : 'phone';
      const challengeValue = !!challengeEmail ? challengeEmail : challengePhone;

      setChallengeKey(challengeKey);

      try {
        setVerifying(true);
        const { data } = await axios({
          method: 'POST',
          url: `${environment.API_URL}/hub/${challengeType?.replace(/ /g,'') === 'verification' ? 'verification' : 'auth'}/complete`,
          responseType: 'json',
          data: {
            token: challengeToken,
            challenge_id: challengeId,
            [challengeKey]: challengeValue,
          },
        });

        if (data?.app_name) {
          setAppName(data.app_name);
        }

        if (data?.redirect_url && !disableRedirect) {
          setIsRedirecting(true);
          let urlParts = new URL(data.redirect_url);
          setRedirectDomain(urlParts.hostname);
          setTimeout(() => window.location.assign(data.redirect_url), 2000);
        }

        if (data?.initiating_user_agent_type) {
          setInitiatingUserAgentType(data.initiating_user_agent_type);
        }

        setVerified(true);
        setCompleteError(null);
      } catch (err: any) {
        switch (err?.response?.data?.code) {
          case 'E_USER_NOT_FOUND':
            setCompleteError({
              show: true,
              canRetry: false,
              type: CompleteErrorTypes.UserNotFound
            });
            return;
          case 'E_USER_FOUND':
            setCompleteError({
              show: true,
              canRetry: false,
              type: CompleteErrorTypes.UserFound
            });
            return;
          default:
            break;
        }

        switch (err?.response?.data?.messages[0]) {
          case 'The auth challenge has expired':
            resendEmail();
            setCompleteError({
              show: true,
              canRetry: false,
              type: CompleteErrorTypes.Expired
            });
            break;
          case 'The requested resource could not be found.':
            setCompleteError({
              show: true,
              canRetry: false,
              type: CompleteErrorTypes.NotFound
            });
            break;
          default: 
            setCompleteError({
              show: true,
              canRetry: false
            });
        }
      } finally {
       
        if(!resending){
          setVerifying(false);
        }
      }
    })();
  }, [props.location.search]);

  const resendEmail = async () => {
    const queryParams = new URLSearchParams(props.location.search);
    try {
      setResending(true);
      await axios({
        method: 'POST',
        url: `${environment.API_URL}/hub/auth/challenge_resend`,
        responseType: 'json',
        data: {
          challenge_id: queryParams.get('id'),
        },
      });
      setResending(true);
    } catch (err: any) {
      setResendError(err.message);
    } finally {
      setResendingComplete(true);
    }
  };

  const darkModeEnabled =
    attributes?.darkMode === 'enabled' ||
    (attributes?.darkMode === 'auto' && preferredColorMode === 'dark') ||
    false;
  const backgroundColor = attributes?.backgroundColor;
  const displayVisualSwoops = attributes?.visualSwoops !== 'false';
  const fontFamily = attributes?.fontFamily;
  const hideVerificationIcons =
    attributes?.hideVerificationIcons &&
    attributes?.hideVerificationIcons === 'true';
  const primaryColor = attributes.primaryColor;
  const primaryColorDark = attributes.primaryColorDark;

  useEffect(() => {
    loadSpecificFont(fontFamily);
  }, [fontFamily]);

  useEffect(() => {
    if (primaryColor) document.documentElement.style.setProperty('--primary-color', primaryColor);
    if (primaryColorDark) document.documentElement.style.setProperty('--primary-color-dark', primaryColorDark);
    if (backgroundColor) document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [primaryColor, primaryColorDark, backgroundColor]);

  return (
    <div
      className={`hub-auth-v2 ${darkModeEnabled ? 'hub-auth-v2__dark-mode' : ''
        }`}
      style={{ fontFamily: fontFamily }}
    >
      <div
        style={{
          background: backgroundColor
            ? backgroundColor
            : (!darkModeEnabled && attributes?.primaryColor) || undefined,
        }}
        className="hub-auth-v2__background"
      />
      <div
        className="hub-auth-v2__container"
        style={{
          background: backgroundColor
            ? newHexShade(backgroundColor, 10)
            : undefined
        }}
      >
        {verifying ? (
          <div className="hub-auth-v2__verifying-content">
            {attributes?.emailImage && (
              <img
                className="hub-auth-v2__logo"
                src={attributes.emailImage}
                alt="missing"
              />
            )}
            <h1 className="hub-auth-v2__header">{t('Verifying...')}</h1>
          </div>
        ) : (
          <>
            {verified ? (
              <div className="hub-auth-v2__verified-content">
                {attributes?.emailImage && (
                  <img
                    className="hub-auth-v2__logo"
                    src={attributes.emailImage}
                    alt="missing"
                  />
                )}
                <h1 className="hub-auth-v2__header">
                  {t(
                    disableRedirect
                      ? 'Your {{challengeKey}} has been verified.'
                      : 'Thanks for verifying your {{challengeKey}}',
                    {
                      challengeKey:
                        t(challengeKey).charAt(0).toLowerCase() +
                        t(challengeKey).slice(1),
                    }
                  )}
                </h1>
                {!isRedirecting &&
                  (initiatingUserAgentType === 'mobile' ? (
                    <p>
                      {t(
                        'Return to {{app_name}} on your mobile device to continue.',
                        { app_name: appName }
                      )}
                    </p>
                  ) : (
                    <p>
                      {t(
                        disableRedirect
                          ? 'Please return to the app to continue. You may close this window.'
                          : 'You can return to your open browser tab.'
                      )}
                    </p>
                  ))}
                {isRedirecting && (
                  <p>
                    <span className="hub-loading-circle"/>
                    {t('Redirecting you back to {{app_name}}', {
                      app_name: appName ? appName : redirectDomain,
                    })}
                  </p>
                )}
                {/* <img src={`/images/${challengeKey}-verify.svg`} alt={t('verified {{challengeKey}}', { challengeKey: t(challengeKey) })} /> */}
                {!hideVerificationIcons && (
                  <div className="hub-auth-v2__verify-icon">
                    <Lottie
                      className="rph-lottie-svg"
                      animationData={
                        challengeKey === 'email' ? EmailSuccess : PhoneSuccess
                      }
                      loop={false}
                    />
                  </div>
                )}
                {!isRedirecting && (
                  <button
                    className="hub-auth-v2__button"
                    onClick={() => window.close()}
                  >
                    {t('Close')}
                  </button>
                )}
                {isRedirecting && (
                  <p className="verified-content__body">
                    {t('This should only take a moment.')}
                  </p>
                )}
              </div>
            ) : (
              <div className="hub-auth-v2__failure-content">
                {attributes?.emailImage && (
                  <img
                    className="hub-auth-v2__logo"
                    src={attributes.emailImage}
                    alt="missing"
                  />
                )}
                <h1 className="hub-auth-v2__header">
                  {t({
                    [CompleteErrorTypes.Expired]: 'Link expired. Check your email for a new link',
                    [CompleteErrorTypes.NotFound]: 'Link not found',
                    [CompleteErrorTypes.UserFound]: 'Verification failed',
                    [CompleteErrorTypes.UserNotFound]: 'Verification failed',
                    '': 'Verification failed'
                  }[completeError?.type || ''])}
                </h1>
                {completeError?.show && (
                  <p>{t({
                    [CompleteErrorTypes.Expired]: 'This link is either expired or has already been used. We sent you a new link.',
                    [CompleteErrorTypes.NotFound]: 'Looks like something went wrong, try clicking on the original link again or re-visiting the original site.',
                    [CompleteErrorTypes.UserFound]: "Another account with this identifier already exists, so we can't add it to your profile. If you need assistance, please contact support@rownd.io.",
                    [CompleteErrorTypes.UserNotFound]: 'We\'re sorry, but this account no longer exists. Please try signing in again.',
                    '': challengeKey === 'phone' ? 'Looks like something went wrong verifying your phone number. Please return to the original app to restart the verification process.' : 'Looks like something went wrong verifying your email. Please return to the original app to restart the verification process.',
                  }[completeError?.type || ''])}</p>
                )}
                {!hideVerificationIcons && (
                  <div className="hub-auth-v2__verify-icon">
                    <Lottie
                      className="rph-lottie-svg"
                      animationData={
                        {
                          [CompleteErrorTypes.Expired]: LinkFail,
                          [CompleteErrorTypes.NotFound]: LinkFail,
                          [CompleteErrorTypes.UserFound]: challengeKey === 'email' ? EmailFail : PhoneFail,
                          [CompleteErrorTypes.UserNotFound]: challengeKey === 'email' ? EmailFail : PhoneFail,
                          '': challengeKey === 'email' ? EmailFail : PhoneFail
                        }[completeError?.type || '']
                      }
                      loop={false}
                    />
                  </div>
                )}
                {completeError?.canRetry ? (
                  <button
                    className="hub-auth-v2__button"
                    onClick={() => resendEmail}
                  >
                    {t('Resend message')}
                  </button>
                ) : (
                  <p>
                    {appName && t('Please return to {{app_name}} and try again.', { app_name: appName })}
                    {!appName && t('Please return to the app and try again.')}
                  </p>
                )}
                <button
                  className="hub-auth-v2__button-link"
                  onClick={() => window.close()}
                >
                  {t('Close this window')}
                </button>
              </div>
            )}
          </>
        )}

        {(verified || verifying) && postDelay && (
          <button
            className="hub-auth-v2__button-link"
            onClick={() => setModal(ModalTypes.NotWorking)}
            style={{ textDecoration: 'underline' }}
          >
            {t('Not working?')}
          </button>
        )}

        {displayVisualSwoops && (
          <Swoops
            darkModeEnabled={darkModeEnabled}
          />
        )}
      </div>
      {modal === ModalTypes.NotWorking && (
        <NotWorkingModal onClose={() => setModal(null)} />
      )}
    </div>
  );
}

HubVerify.propTypes = {
  history: PropTypes.object.isRequired,
  module: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
