import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';


export const renderInput = ({ input, label, type, className = '',readOnly=false, meta: { touched, error, warning } }) => (
  <div className="form-group">
    {/* <label>{label}</label> */}
      <input {...input} placeholder={label} readOnly={readOnly} type={type} autoComplete="off" className={`form-control ${className}`}/>
      {touched && ((error && <span className={`hs-auth-error ${input.name==='password' ? 'rownd-error-include' : ''}`}
       dangerouslySetInnerHTML={{__html: error}} ></span>) || (warning && <span className="hs-auth-error">{warning}</span>))}
  </div>
);

export const renderInputwithLabel = ({ input, label, type, className = '', placeholder = label, manuallyError= false, meta: { touched, error, warning } }) => (
  <div className="form-group">
    <label>{label}</label>
      <input {...input} placeholder={placeholder} type={type} autoComplete="off" className={`form-control ${className}`}/>
      {touched && ((error && <span className="hs-auth-error">{error}</span>) || (warning && <span className="hs-auth-error">{warning}</span>))}
      {manuallyError && (<span className="hs-auth-error">Required</span>)}
  </div>
);

export const renderEmailInput = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div className="form-group">
    <label className={`${touched && error && 'hs-auth-star-error'}`}>{label} <span >*</span></label>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon3">https://www.</span>
      </div>
      <input {...input} placeholder={label} type={type} autoComplete="off" className="form-control"/>
    </div>
    {touched && ((error && <span className="hs-auth-error">{error}</span>) || (warning && <span className="hs-auth-error">{warning}</span>))}
  </div>
);


export const renderTextArea = ({ input, rows, label, placeholder = label, meta: { touched, error, warning } }) => (
  <div className="form-group">
    {label && (<label>{label}</label>)}
      <textarea {...input} placeholder={placeholder}  rows={rows} className="form-control"> </textarea>
      {touched && ((error && <span className="hs-auth-error">{error}</span>) || (warning && <span className="hs-auth-error">{warning}</span>))}
  </div>
);


// export const renderSelectField = ({ input, label, meta: { touched, error }, children }) => (
//   <div>
//       <label>{label}</label>
//       <div>
//           <select {...input}>
//               {children}
//           </select>
//           {touched && error && <span className="text-danger">{error}</span>}
//       </div>
//   </div>
// );

export const renderSelectField = ({ input,className='', options , label, manuallyError=false, disabled=false, meta: { touched, error } }) =>  (
  <div>
      {label !== '' && (<label>{label}</label>)}
      <div>
        <Select
          {...input}
          className={className}
          options={options}
          // getOptionLabel={({ text }) => text}
          // getOptionValue={({id}) => id}
          onBlur={() => input.onBlur(input.value)}
          // onBlurResetsInput={false}
          isDisabled={disabled}
        />
          {touched && error && <span className="text-danger">{error}</span>}
          {manuallyError && (<span className="hs-auth-error">Required</span>)}
      </div>
  </div>
);


export const renderOption = (values) => {
  if (!values || values.length === 0) {
      return null;
  }

  var options = values.map(function (value, index) {
      return (
          <option key={index} value={value.id}>{value.text}</option>
      );
  });
  return options;
};

export const renderCheckboxwithLabel = ({ input, label, type, className = '', placeholder = label, disabled=false, meta: { touched, error, warning } }) => (
  <div className="form-group">
      <input {...input} id={input.name} placeholder={placeholder} type={type} autoComplete="off" className={`form-control ${className}`} disabled={disabled}/>

    <label htmlFor={input.name} dangerouslySetInnerHTML={{__html: label}}></label>
      {touched && ((error && <span className="hs-auth-error">{error}</span>) || (warning && <span className="hs-auth-error">{warning}</span>))}
  </div>
);

export const renderCreatableSelectField = ({ input,className='', options , label, manuallyError=false, disabled=false, onCreateOption, meta: { touched, error } }) =>  (
  <div>
      {label !== '' && (<label>{label}</label>)}
      <div>
        <CreatableSelect
          {...input}
          className={className}
          options={options}
          // getOptionLabel={({ text }) => text}
          // getOptionValue={({id}) => id}
          onBlur={() => input.onBlur(input.value)}
          // onBlurResetsInput={false}
          isDisabled={disabled}
          onCreateOption={onCreateOption}
        />
          {touched && error && <span className="text-danger">{error}</span>}
          {manuallyError && (<span className="hs-auth-error">Required</span>)}
      </div>
  </div>
);

renderInput.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  type: PropTypes.any,
  meta: PropTypes.any,
  className: PropTypes.any,
  readOnly: PropTypes.any
};


renderInputwithLabel.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  type: PropTypes.any,
  meta: PropTypes.any,
  className: PropTypes.any,
  placeholder: PropTypes.any,
  manuallyError:PropTypes.any
};


renderCheckboxwithLabel.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  type: PropTypes.any,
  meta: PropTypes.any,
  className: PropTypes.any,
  placeholder: PropTypes.any,
  disabled: PropTypes.any
};


renderEmailInput.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  type: PropTypes.any,
  meta: PropTypes.any
};

renderSelectField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  children: PropTypes.any,
  options: PropTypes.any,
  meta: PropTypes.any,
  className: PropTypes.any,
  manuallyError:PropTypes.any,
  disabled: PropTypes.any
};

renderCreatableSelectField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  children: PropTypes.any,
  options: PropTypes.any,
  meta: PropTypes.any,
  className: PropTypes.any,
  manuallyError:PropTypes.any,
  disabled: PropTypes.any,
  onCreateOption: PropTypes.any
};

renderTextArea.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.any,
  rows: PropTypes.any,
  placeholder: PropTypes.any
};
