import axios from 'axios';
import { environment } from '../../environment.js';
// import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import {errorMessage} from '../../util/errorComponent';
import {
  SET_LOGIN_USER,
  RESET_USER,
  END_USER_SET_APPLICATION,
  SET_APP_ID,
  SET_APP_USER_DATA,
  SET_APPLICATION_DATA_SCHEMA,
  SET_USER_SHARED_ACLS,
  SET_MY_USER_DATA,
  SET_USER_APPLICATIONS,
  SET_USER_APPLICATION_SCHEMA,
  SET_EMAIL_VERIFY,
  SET_USER_EMAIL,
  SET_EMAIL_VERIFY_ERROR,
  SET_ACTIVE_PAGE,
  SET_IS_FETCHING_APPLICATIONS,
} from './types';

export const setActivePage = (page) => {
  return {
    type: SET_ACTIVE_PAGE,
    payload: page,
  };
};


export const setApplication = (application) => {
  return {
    type: END_USER_SET_APPLICATION,
    payload: application,
  };
};

export const loginAction = (values,history) => async(dispatch) =>{
	try {
    dispatch(setLoginUser(true));
    const url = '/user';
    history.push(url);
  } catch (err) {
    if(err.response) {
      // const { message } = err.response.data;
      // toast.error(message);
    }
  }
};

export const setLoginUser = (data) => {
  // window.localStorage.setItem('endUser', JSON.stringify(data));
  axios.defaults.headers.common.Authorization = data.token;
	return {
    type: SET_LOGIN_USER,
    payload: data
  };
};

export const resetUser = () => async (dispatch) => {
  localStorage.removeItem('endUser');
  delete axios.defaults.headers.common.Authorization;
  dispatch(
    {
      type: RESET_USER,
    }
  );
};

export const getApplication = (appId, history, opts = { force_refresh: false }) => async (dispatch, getState) => {
  // We might already have the app in memory, so don't re-fetch unless requested
  if (!opts.force_refresh) {
    let { endUser } = getState();
    let app = endUser.applications.find(app => app.id === appId);
    if (app) {
      dispatch(setApplication(app));
      return;
    }
  }

  try {
    const { data } = await axios.get(`${environment.API_URL}/applications/${appId}`);
    dispatch(setApplication(data));
  } catch (err) {
    history.push('/apps');
    console.error('Failed to load app:', err);
  }
};

export const saveAppId = (appId) => {
  return {
    type: SET_APP_ID,
    payload: appId,
  };
};

export const associateUserToken = (verificationToken) => async () => {
  try {
    await axios.post(`${environment.API_URL}/users/signup/complete`, { verification_token: verificationToken });
  } catch (err) {
    console.error(err);
  }
};

export const userAssociation = (param) => async(dispatch, getState) => {
  const {appId} = getState().endUser;
  try {
    const { data } = await axios.post(`${environment.API_URL}/applications/${appId}/users/association`, param);
    const payload = {
      user_id: data.application_user_id
    };
    dispatch(setUserData(payload));
    // history.push('/dashboard');
  } catch (err) {
    if(err.response) {
      const { messages } = err.response.data;
      toast.error(messages[0]);
    }
    else {
      toast.error(errorMessage(['something went wrong, please try again']));
    }
  }
};

export const getApplicationSchema = (appId) => async(dispatch) => {
  try {
    const { data } =  await axios.get(`${environment.API_URL}/me/applications/${appId}/schema`);
    dispatch(setApplicationDataSchema(data));
  } catch (err) {
    // if(err.response) {
    //   const { message } = err.response.data;
    //   toast.error(message);
    // }
    // else {
    //   toast.error('something went wrong, please try again');
    // }
  }
};

export const setApplicationDataSchema = (payload) => {
  return {
    type: SET_APPLICATION_DATA_SCHEMA,
    payload
  };
};

export const getAppUserData = () => async(dispatch, getState) => {
  const {appId} = getState().endUser;
  try {
    const { data } =  await axios.get(`${environment.API_URL}/me/applications/${appId}/data`);
    dispatch(setAppUserData(data));
  } catch (err) {
    if(err.response) {
      const { messages } = err.response.data;
      toast.error(errorMessage(messages));
    }
    else {
      toast.error(errorMessage(['something went wrong, please try again']));
    }
  }
};

export const setAppUserData = (payload) => {
  return {
    type: SET_APP_USER_DATA,
    payload
  };
};

export const updateUserData = (param) => async(dispatch, getState) => {
  const {appId} = getState().endUser;
  try {
    const { data } =  await axios.put(`${environment.API_URL}/me/applications/${appId}/data`, param);
    dispatch(setAppUserData(data));
    dispatch(getUserSharedAcls());
  } catch (err) {
    if(err.response) {
      const { messages } = err.response.data;
      toast.error(errorMessage(messages));
    }
    else {
      toast.error(errorMessage(['something went wrong, please try again']));
    }
  }
};

export const setUserSharedAcls = (payload) => {
  return {
    type: SET_USER_SHARED_ACLS,
    payload
  };
};

export const getUserSharedAcls = () => async(dispatch, getState) => {
  const {appId} = getState().endUser;
  try {
    const { data } =  await axios.get(`${environment.API_URL}/me/applications/${appId}/acls`);
    dispatch(setUserSharedAcls(data));
  } catch (err) {
    // if(err.response) {
    //   const { message } = err.response.data;
    //   toast.error(message);
    // }
    // else {
    //   toast.error('something went wrong, please try again');
    // }
  }
};


export const saveUserSharedAcls = (acls) => async(dispatch, getState) => {
  const {appId} = getState().endUser;
  try {
    const { data } =  await axios.put(`${environment.API_URL}/me/applications/${appId}/acls`, acls);
    dispatch(setUserSharedAcls(data));
  } catch (err) {
    if(err.response) {
      const { messages } = err.response.data;
      toast.error(errorMessage(messages));
    }
    else {
      toast.error(errorMessage(['something went wrong, please try again']));
    }
  }
};

export const setUserData = (payload) => {
  return {
    type: SET_MY_USER_DATA,
    payload
  };
};

export const getMyApplicationData = (appId) => async(dispatch) => {
  dispatch(saveAppId(appId));
  try {
    const { data } =  await axios.get(`${environment.API_URL}/me/applications/${appId}/data`);
    dispatch(setUserData(data.data));
    dispatch(setAppUserData(data));
  } catch (err) {
    if(err.response) {
      const { messages} = err.response.data;
      toast.error(errorMessage(messages));
    }
    // else {
      // toast.error('something went wrong, please try again');
    // }
  }
};

export const setUserApplications = (payload) => {
  return {
    type: SET_USER_APPLICATIONS,
    payload
  };
};

export const setIsFetchingApplications = () => {
  return {
    type: SET_IS_FETCHING_APPLICATIONS,
    payload: { isFetchingApplications: true }
  };
};

export const getMyApplications = () => async(dispatch, getState) => {
  let { isFetchingApplications } = getState().endUser;

  if(isFetchingApplications) {
    return;
  }

  dispatch(setIsFetchingApplications());

  try {
    const { data } =  await axios.get(`${environment.API_URL}/me/applications`);
    dispatch(getAllApplicationSchema(data.results));
    dispatch(setUserApplications(data.results));
  } catch (err) {
    console.log('err', err);
  }
};

export const setApplicationSchema = (payload) => {
  return {
    type: SET_USER_APPLICATION_SCHEMA,
    payload
  };
};


const getAllApplicationSchema= (applications) => async (dispatch) =>  {
  applications.map(async (application) => {
    try {
      const { data } =  await axios.get(`${environment.API_URL}/me/applications/${application.id}/schema`);
      const payload = {
        application,
        schema: data
      };
      dispatch(setApplicationSchema(payload));
    } catch (err) {
      console.log('error', err);
    }

  });
};

export const setEmailVerify = (payload) => {
  return {
    type: SET_EMAIL_VERIFY,
    payload
  };
};

export const setEmailVerifyError = (payload) => {
  return {
    type: SET_EMAIL_VERIFY_ERROR,
    payload
  };
};
export const verifyToken= (param) => async (dispatch) =>  {
  try {
    const { data } =  await axios.post(`${environment.API_URL}/users/signup/verify`, param);
    dispatch(setEmailVerify(data));
  } catch (err) {
    dispatch(setEmailVerifyError(true));
  }
};

export const setUserEmail = (payload) => {
  return {
    type: SET_USER_EMAIL,
    payload
  };
};

export const sendVerificationEmail =  (param, history)  => async(dispatch) => {
  try {
    await axios.post(`${environment.API_URL}/users/signup/init`, param);
    dispatch(setUserEmail(param.email));
    history.push('/email');
  } catch (err) {
    if(err.response) {
      const { messages } = err.response.data;
      toast.error(errorMessage(messages));
    }
  }
};

export const getApplicationPublic = (appId, history) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${environment.API_URL}/applications/${appId}/public`);
    dispatch(setApplication(data));
  } catch (err) {
    history.push('/apps');
    console.log('error', err);
  }
};

export const deleteUserData = (userId, history) => async(dispatch, getState) => {
  const {appId} = getState().endUser;
  try {
    await axios.delete(`${environment.API_URL}/me/applications/${appId}/data`);
    history.push('/apps');
  } catch (err) {
    if(err.response) {
      const { messages } = err.response.data;
      toast.error(errorMessage(messages));
    }
    else {
      toast.error(errorMessage(['something went wrong, please try again']));
    }
  }
};