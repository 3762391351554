
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
// import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import './Login.scss';
import {loginAction} from '../../../redux/actions/endUser';
import {required } from '../../../util/validation';
// import { Modal } from 'react-bootstrap';
import {renderInputwithLabel} from '../../../util/renderComponent';
import {getApplication, getMyApplicationData, userAssociation, getApplicationSchema} from '../../../redux/actions/endUser';
import {iconUrl} from '../../../util/validation';

class Login extends Component{

  constructor(props) {
    super(props);
    this.state ={
      socialProfile: {},
      enterEmail: false,
      key:'321',
      loginLinkModal: false,
      loading: false
    };
  }

  async componentDidMount() {
    const {appId} = this.props.match.params;
    const {appDataSchema} = this.props;
    this.setState({loading: true});
    await this.props.getMyApplicationData(appId, this.props.history);
    await this.props.getApplication(appId, this.props.history);
    if(!appDataSchema.schema) {
      await this.props.getApplicationSchema(appId);
    }
    this.setState({loading: false});
  }

  login = async () => {
    const {appDataSchema} = this.props;
    const param = {
      challenge_field: appDataSchema.user_verification_field
    };
    await this.props.userAssociation(param, this.props.history);
    // this.setState({loginLinkModal: true});
  };



render() {
    const { handleSubmit, submitting } = this.props;
    const {loading} = this.state;
    const {application} = this.props;
        return (

            <div className="rownd-user-welcome-pages-main">
               {application && !loading ? (
              <div className="rownd-welcome-page-main">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                              <div className="rownd-welcome-section-main">
                                  <div className="">
                                      <h1 className="rownd-project-title-main">Welcome to your Personal Data Center</h1>
                                      <p>ROWND is an secure and transparent storage and management platform for your data. With</p>
                                      <p>ROWND you can control what the data you share is used for and who has access to is.</p>
                                      <div className="rownd-american-card-block-main">
                                          <div className="rownd-american-card">
                                              <img src={iconUrl(application.icon)} alt="logo" />
                                          </div>
                                          <p>Our partner <strong>{application.name}</strong> uses Rownd to safely store your data and allow you to manage it.</p>
                                      </div>
                                  </div>
                                  <div className="">
                                      <p>To access and manage your data, share your email and create a ROWND account.</p>
                                      <div className="rownd-american-card-block-main">
                                          <form onSubmit={handleSubmit(this.login)}>
                                            <Field name="email" type="text"
                                                component={renderInputwithLabel} label="" placeholder="your email"
                                                validate={[required]}
                                            />
                                            <div className="rownd-form-btn">
                                                <button type="submit" disabled={submitting ? 'disabled' : ''} className="rownd-theme-btns-main">{submitting  ? 'Loading...' : 'Access my Data'}</button>
                                            </div>
                                          </form>
                                          {/* <p>If your are already a ROWND member, <a href="">Login.</a></p> */}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
               ) : (
                <div className="rownd-loader-icon">
                    <img src="/images/loader-rownd.gif" alt="Rownd" />
                </div>
              )}
          </div>
        );
    }
}


Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  loginAction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  getApplication: PropTypes.func.isRequired,
  application: PropTypes.object,
  userAssociation: PropTypes.func.isRequired,
  appDataSchema: PropTypes.object.isRequired,
  getApplicationSchema: PropTypes.func.isRequired,
  getMyApplicationData: PropTypes.func.isRequired
};


const mapStateToProps = (state) => {
  const { user} = state.auth;
  return {
    user: state.auth.user,
    appDataSchema: state.endUser.appDataSchema,
    application: state.endUser.application,
    initialValues: {
      email: user && user.email ? user.email : '',
    }
  };
};

const mapDispatchToProps = {
  loginAction,
  getApplication,
  userAssociation,
  getApplicationSchema,
  getMyApplicationData
};

const loginForm =  reduxForm({
	form: 'login'
  })(Login);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(loginForm);
