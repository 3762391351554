import './Footer.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Footer extends Component{

    render() {
        return (
            <footer className="rownd-welcome-footer-bar-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <div className="rownd-rights-link">
                                © {new Date().getFullYear()} Rownd, Inc.
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-6">
                            <div className="wagdt-terms-link-bloc">
                                <a href="https://rownd.io/privacy" className="rownd-welcome-footer-details-main" rel="noopener noreferrer" target="_blank">Privacy</a>
                                <a href="https://rownd.io/terms-and-conditions" className="rownd-welcome-footer-details-main" rel="noopener noreferrer" target="_blank">Terms</a>
                                <a href="mailto:help@rownd.io" className="rownd-welcome-footer-details-main">help@rownd.io</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

Footer.propTypes = {
};

const mapStateToProps = (state) => {
  return {
    state
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
